<template>
  <!-- Offcanvas area start -->
  <div class="fix">
    <div :class="this.style1">
      <div class="offcanvas__wrapper">
        <div class="offcanvas__content">
          <div class="offcanvas__top mb-40 d-flex justify-content-between align-items-center">
            <div class="offcanvas__logo">
              <a href="/">
                <img src="../assets/vslogo.svg" alt="logo not found" width="130">
              </a>
            </div>
            <div class="offcanvas__close">
              <button @click="this.openMenu"><i class="fal fa-times"></i></button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div :class="this.style2"></div>
  <div class="offcanvas__overlay-white"></div>
  <!-- Offcanvas area start -->

  <!-- Header area start -->
  <header>
    <div id="header-sticky" class="header__area header__transparent">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-2 col-6">
            <div class="header__logo">
              <a href="/">
                <img src="../assets/vslogo.svg" alt="logo not found" width="188">
              </a>
            </div>
          </div>
          <div class="col-xl-6 col-lg-9 d-none d-lg-block">
            <div class="menu__main-wrapper d-flex justify-content-center">
              <div class="main-menu d-none d-none d-lg-block">
                <nav id="mobile-menu">
                  <ul>
                    <li><router-link to="/">首页</router-link></li>
                    <li><router-link to="/jipd" ><span class="down__mark-line">免费的机票单</span></router-link></li>
                    <li><a href="https://main.vsvisa.com" target="_blank">申根机酒行助手</a></li>
                    <li><a href="javascript:void(0);">常见问题</a></li>
                    <li><a href="javascript:void(0);">联系我们</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-1 col-6">
            <div class="header__right d-flex align-items-center justify-content-end">
              <div class="header__btn d-none d-xl-block">
                <main-user ref="mainuser" ></main-user>
              </div>
              <div class="header__toggle d-lg-none">
                <a class="sidebar__active" href="javascript:void(0)" @click="openMenu()">
                  <div class="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
              </div>
              <div class="header__hamburger d-none d-lg-block ml-60">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Header area end -->

</template>
<script>
import MainUser from '@/components/MainUser.vue'

export default {
  name: 'publicTit',
  data() {
    return {
      mOpen: false, // 菜单是否开启
      style1: 'offcanvas__info',
      style2: 'offcanvas__overlay',
    }
  },
  components: {
    MainUser
  },
  methods: {
    openMenu() {
      this.mOpen = !this.mOpen
      if (this.mOpen) {
        this.style1 = 'offcanvas__info'
        this.style2 = 'offcanvas__overlay'
      } else {
        this.style1 = 'offcanvas__info info-open'
        this.style2 = 'offcanvas__overlay overlay-open'
      }
    },
    clearMenu() {
      this.mOpen = false
      this.style1 = 'offcanvas__info'
      this.style2 = 'offcanvas__overlay'
    }
  }
}
</script>

<style scoped>

</style>
